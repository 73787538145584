<template>
  <div>
    <!--  详情-->
    <div class="userdetail">
      <div class="smalldiv">
        <div class="smalltitle">姓&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp名</div>
        <div style="padding-top: 4px">
          <el-input
            v-model="user.realName"
            size="small"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">性&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp别</div>
        <div style="padding-top: 12px">
          <el-radio-group v-model="user.sex" size="small">
            <el-radio :label="0">男</el-radio>
            <el-radio :label="1">女</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">手机号码</div>
        <div class="text">{{ user.contactPhone }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">毕业年份</div>
        <div class="text">{{ user.graduateYear }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">高考省份</div>
        <div class="text">{{ user.examProvince }}</div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">户籍地区</div>
        <div>
          <el-select
            v-model="user.residenceProvince"
            size="small"
            placeholder="请选择"
            @change="getresidenceCity"
            style="margin-right: 10px; padding-top: 4px"
          >
            <el-option
              v-for="item in optionProvince"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="user.residenceCity"
            size="small"
            @change="getcounty"
            placeholder="请选择"
            style="margin-right: 10px; padding-top: 4px"
          >
            <el-option
              v-for="item in cityOption"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-select
            clearable
            v-model="user.residenceCounty"
            size="small"
            placeholder="请选择"
            style="padding-top: 4px"
          >
            <el-option
              v-for="item in countyOption"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">民&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp族</div>
        <div>
          <el-select
            v-model="user.nation"
            size="small"
            placeholder="请选择"
            style="padding-top: 4px"
          >
            <el-option
              v-for="item in nationData"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">所在中学</div>
        <div class="text">
          {{ user.schoolName }}
          <span @click="changeschool" style="cursor: pointer;">修改</span>
        </div>
      </div>
      <div class="smalldiv">
        <div class="smalltitle">是否色盲</div>
        <div style="padding-top: 12px">
          <el-radio-group v-model="user.colorWeakness" size="small">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">未知</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="edit">
        <div class="button" @click="saveStudentInfo()">保存</div>
      </div>
    </div>
    <el-dialog title="选择中学" :visible.sync="dialogVisible" width="720px">
      <div style="margin-bottom:10px;margin-left:10px">
        <el-input v-model="keyword" placeholder="请输入内容" size="small" style="width:150px"></el-input> 
        <el-button @click="schoolpage(keyword)" size="small" style="margin-left:20px" type="primary">搜 索</el-button>
      </div>
      <el-radio-group v-model="radio" size="small">
        <el-radio :label="item" border v-for="item in midellschool" :key="item.schoolId" style="margin:10px;width:150px">{{item.shortName}}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="sureschool" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getProvinceList, getCountyList, getCityList } from "@/api/index.js";
import { getStudentDetail, schoolpage,saveStudentInfo } from "@/api/personalCenter.js";
export default {
  data() {
    return {
      userId: "",
      user: {},
      radio: {},
      keyword: "",
      dialogVisible: false,
      midellschool: [],//中学
      optionProvince: [], //省份
      cityOption: [], //城市
      countyOption: [], //区
      nationData: [
        {
          id: "01",
          name: "汉族",
        },
        {
          id: "02",
          name: "蒙古族",
        },
        {
          id: "03",
          name: "回族",
        },
        {
          id: "04",
          name: "藏族",
        },
        {
          id: "05",
          name: "维吾尔族",
        },
        {
          id: "06",
          name: "苗族",
        },
        {
          id: "07",
          name: "彝族",
        },
        {
          id: "08",
          name: "壮族",
        },
        {
          id: "09",
          name: "布依族",
        },
        {
          id: "10",
          name: "朝鲜族",
        },
        {
          id: "11",
          name: "满族",
        },
        {
          id: "12",
          name: "侗族",
        },
        {
          id: "13",
          name: "瑶族",
        },
        {
          id: "14",
          name: "白族",
        },
        {
          id: "15",
          name: "土家族",
        },
        {
          id: "16",
          name: "哈尼族",
        },
        {
          id: "17",
          name: "哈萨克族",
        },
        {
          id: "18",
          name: "傣族",
        },
        {
          id: "19",
          name: "黎族",
        },
        {
          id: "20",
          name: "傈僳族",
        },
        {
          id: "21",
          name: "佤族",
        },
        {
          id: "22",
          name: "畲族",
        },
        {
          id: "23",
          name: "高山族",
        },
        {
          id: "24",
          name: "拉祜族",
        },
        {
          id: "25",
          name: "水族",
        },
        {
          id: "26",
          name: "东乡族",
        },
        {
          id: "27",
          name: "纳西族",
        },
        {
          id: "28",
          name: "景颇族",
        },
        {
          id: "29",
          name: "柯尔克孜族",
        },
        {
          id: "30",
          name: "土族",
        },
        {
          id: "31",
          name: "达斡尔族",
        },
        {
          id: "32",
          name: "仫佬族",
        },
        {
          id: "33",
          name: "羌族",
        },
        {
          id: "34",
          name: "布朗族",
        },
        {
          id: "35",
          name: "撒拉族",
        },
        {
          id: "36",
          name: "毛难族",
        },
        {
          id: "37",
          name: "仡佬族",
        },
        {
          id: "38",
          name: "锡伯族",
        },
        {
          id: "39",
          name: "阿昌族",
        },
        {
          id: "40",
          name: "普米族",
        },
        {
          id: "41",
          name: "塔吉克族",
        },
        {
          id: "42",
          name: "怒族",
        },
        {
          id: "43",
          name: "乌孜别克族",
        },
        {
          id: "44",
          name: "俄罗斯族",
        },
        {
          id: "45",
          name: "鄂温克族",
        },
        {
          id: "46",
          name: "崩龙族",
        },
        {
          id: "47",
          name: "保安族",
        },
        {
          id: "48",
          name: "裕固族",
        },
        {
          id: "49",
          name: "京族",
        },
        {
          id: "50",
          name: "塔塔尔族",
        },
        {
          id: "51",
          name: "独龙族",
        },
        {
          id: "52",
          name: "鄂伦春族",
        },
        {
          id: "53",
          name: "赫哲族",
        },
        {
          id: "54",
          name: "门巴族",
        },
        {
          id: "55",
          name: "珞巴族",
        },
        {
          id: "56",
          name: "基诺族",
        },
      ], //民族
    };
  },
  mounted() {
    this.userId = this.$route.query.userId;
    this.getStudentDetail();
    this.schoolpage();
  },
  methods: {
    changeschool() {
      this.radio = {}
      this.keyword = ""
      this.dialogVisible = true;
    },
    //获取详情
    getStudentDetail() {
      getStudentDetail({ userId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.user = res.data || {};
          this.getProvinceList();
        }
      });
    },
    sureschool() {
      this.user.schoolName = this.radio.name 
      this.user.schoolId = this.radio.schoolId 
      this.dialogVisible = false;
    },
    schoolpage(keyword) {
      schoolpage({ size: 20, keyword: keyword }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.midellschool = data.records || [];
        }
      });
    },
    // 获取省份
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.optionProvince = res.data || [];
          if (this.user.residenceProvince) {
            this.getresidenceCity(this.user.residenceProvince, 1);
          }
        }
      });
    },
    // 获取城市
    getresidenceCity(val, flag) {
      if (flag != 1) {
        this.user.residenceCity = "";
        this.user.residenceCounty = "";
      }
      let id = "";
      this.optionProvince.forEach((item) => {
        if (item.name == val) {
          id = item.id;
        }
      });
      getCityList({ id: id }).then((res) => {
        if (res.code == 1) {
          this.cityOption = res.data || [];
          if (this.user.residenceCity) {
            this.getcounty(this.user.residenceCity, 1);
          }
        }
      });
    },
    // 获取区域
    getcounty(val, flag) {
      if (flag != 1) {
        this.user.residenceCounty = "";
      }
      let id = "";
      this.cityOption.forEach((item) => {
        if (item.name == val) {
          id = item.id;
        }
      });
      getCountyList({ id: id }).then((res) => {
        if (res.code == 1) {
          this.countyOption = res.data || [];
        }
      });
    },
    // 修改
    saveStudentInfo() {
      console.log('保存');
      saveStudentInfo(this.user).then((res) => {
        if (res.code == 1) {
          this.getStudentDetail();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.userdetail {
  margin-top: 55px;
  margin-left: 120px;
  .smalldiv {
    height: 40px;
    display: flex;
    justify-content: flex-start;
    .smalltitle {
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      text-align: left;
      width: 156px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .text {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
}
span {
  margin-left: 16px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1ab394;
}
.edit {
  height: 300px;
  position: relative;
  .button {
    position: absolute;
    top: 80px;
    left: 400px;
    width: 150px;
    height: 32px;
    background: #1ab394;
    border-radius: 5px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    color: #fff;
  }
}
/deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #1ab394;
    background: #1ab394;
}
/deep/.el-radio__input.is-checked+.el-radio__label {
    color: #1ab394;
}
/deep/.el-radio.is-bordered.is-checked {
    border-color: #1ab394;
}
/deep/.el-button--primary{
    color: #FFF;
    background-color: #1ab394;
    border-color: #1ab394;
}
</style>
